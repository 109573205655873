<template>
  <div>
    <div>
      <div>
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-12">
              <div class="login-card" style="display: block;">
                <div>
                  <div>
                    <a class="logo">
                      <img
                        class="img-fluid for-light"
                        src="@/assets/images/PSICOMETRIX-logo.jpg"
                        alt="looginpage"
                        width="180px"
                      />
                      <img
                        class="img-fluid for-dark"
                        src="@/assets/images/logo/logo_dark.png"
                        alt="looginpage"
                      />
                    </a>
                  </div>

                  <div class="login-main login-form-card">
                    <form
                      class="theme-form font-unv"
                      @submit.prevent="buscarPago(formFind)"
                    >
                      <div class="form-group">
                        <label class="col-form-label ">{{
                          lang.login.inputs.radio_label
                        }}</label>

                        <div
                          class="form-group m-t-15 m-checkbox-inline mb-0 custom-radio-ml"
                        >
                          <div class="radio radio-primary">
                            <input
                              id="radioinline2"
                              type="radio"
                              name="radio1"
                              value="1"
                              :checked="true"
                              v-model="formFind.type_dni"
                            />
                            <label class="mb-0 font-unv" for="radioinline2">{{
                              lang.login.inputs.radio_rut
                            }}</label>
                          </div>
                          <div class="radio radio-primary">
                            <input
                              id="radioinline3"
                              type="radio"
                              name="radio1"
                              value="2"
                              v-model="formFind.type_dni"
                            />
                            <label class="mb-0 font-unv" for="radioinline3">{{
                              lang.login.inputs.radio_other
                            }}</label>
                          </div>
                        </div>
                      </div>

                      <div class="form-group">
                        <label class="col-form-label font-unv">{{
                          "Ingrese RUT o Nro de identificación"
                        }}</label>
                        <label
                          for=""
                          class="col-form-label pt-0 ml-2 text-danger"
                          >*</label
                        >
                        <input
                          :class="
                            $v.formFind.dni.$error === true ||
                            error_min_rut ||
                            error_max_rut ||
                            error_format_rut ||
                            error_min_pass ||
                            error_format_pass
                              ? 'form-control is-invalid font-unv'
                              : 'form-control font-unv'
                          "
                          type="text"
                          placeholder=""
                          v-model.trim="$v.formFind.dni.$model"
                        />

                        <div
                          class="invalid-tooltip font-unv"
                          v-if="$v.formFind.dni.$dirty"
                        >
                          <div v-if="!$v.formFind.dni.required">
                            {{ lang.login.validations.error_dni }}
                          </div>
                        </div>
                        <div class="invalid-tooltip font-unv">
                          <div v-if="error_min_rut">
                            {{ lang.login.validations.error_dni_min }}
                          </div>
                        </div>

                        <div class="invalid-tooltip font-unv">
                          <div v-if="error_max_rut">
                            {{ lang.login.validations.error_dni_max }}
                          </div>
                        </div>

                        <div class="invalid-tooltip font-unv">
                          <div v-if="error_format_rut">
                            {{ lang.login.validations.error_dni_format }}
                          </div>
                        </div>

                        <div class="invalid-tooltip font-unv">
                          <div v-if="error_min_pass">
                            {{ lang.login.validations.error_dni_other_min }}
                          </div>
                        </div>

                        <div class="invalid-tooltip font-unv">
                          <div v-if="error_format_pass">
                            {{ lang.login.validations.error_dni_other_format }}
                          </div>
                        </div>
                      </div>

                      <div class="form-group mb-0">
                        <button
                          class="btn btn-primary btn-block font-unv"
                          type="submit"
                          :disabled="$v.$invalid"
                        >
                          <span
                            v-if="spinner"
                            class="spinner-border spinner-border-sm mr-2"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span v-if="!spinner">Buscar inscrito</span>
                          <span v-if="spinner">{{
                            lang.login.inputs.button_find_save
                          }}</span>
                        </button>
                      </div>

                      <p class="mt-4 mb-0 font-unv">
                        ¿Quieres realizar un nuevo registro?

                        <router-link class="ml-2" tag="a" to="/">
                          Click aqui
                        </router-link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
export default {
  name: "PaymentFind",
  data() {
    return {
      spinner: false,
      formFind: {
        dni: "",
        type_dni: 1,
      },
      error_min_rut: false,
      error_max_rut: false,
      error_format_rut: false,
      error_min_pass: false,
      error_format_pass: false,
    };
  },
  validations: {
    formFind: {
      dni: {
        required,
      },
    },
  },
  methods: {
    validateDni(type) {
      this.error_min_rut = false;
      this.error_max_rut = false;
      this.error_format_rut = false;
      this.error_min_pass = false;
      this.error_format_pass = false;

      if (type == 1) {
        if (this.formFind.dni.length < 9) {
          this.error_min_rut = true;
          return false;
        } else {
          this.error_min_rut = false;
        }

        if (this.formFind.dni.length > 10) {
          this.error_max_rut = true;
          return false;
        } else {
          this.error_max_rut = false;
        }

        if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(this.formFind.dni)) {
          this.error_format_rut = true;
          return false;
        } else {
          this.error_format_rut = false;
          return true;
        }
      } else if (type == 2) {
        if (this.formFind.dni.length < 6) {
          this.error_min_pass = true;
          return false;
        } else {
          this.error_min_pass = false;
        }

        if (!/^([A-Za-z0-9])*$/.test(this.formFind.dni)) {
          this.error_format_pass = true;
          return false;
        } else {
          this.error_format_pass = false;
          return true;
        }
      }
    },
    async buscarPago(form) {
      this.spinner = true;

      try {
        if (!this.validateDni(form.type_dni)) {
          return;
        }

        const pago = await this.axios.post("/payment/find", form);
        this.spinner = false;

        if (pago.data.status == "dni-not-register") {
          this.$swal({
            title: this.lang.login.notifications.pay_warning_title,
            text: pago.data.msg,
            type: "warning",
          });
        } else if (pago.data.status == "pay-fail") {
          this.$swal({
            title: this.lang.login.notifications.pay_warning_title,
            text: pago.data.msg,
            type: "warning",
          });
        } else if (pago.data.status == "pay") {
          this.$swal({
            title: this.lang.login.notifications.pay_warning_title,
            text: pago.data.msg,
            type: "warning",
          });
        } else if (pago.data.status == "recovery") {
          this.$router.push(`/pagar/${pago.data.data.code}`);
        }
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
  },
  computed: {
    ...mapState("Lang", ["lang"]),
  },
};
</script>
